@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import "markdown";

body {
  margin: 0;
  font-family: 'Share Tech Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #95a3ab;
  margin: 0;
  padding: 0;
  background-color: #0e141f;
}

.app--wrapper {
	width: 100%;
	height: auto;
	float: left;

	.app--header-wrapper {
		width: 100%;
		height: 66px;
		border-bottom: 1px solid #273445;
		display: block;
		z-index: 1000;
		background: #0e141f;
		position: fixed;

		.app--header-content {
			width: 100%;
			max-width: 900px;
			min-width: 300px;
			margin-left: auto;
			margin-right: auto;

			.app--site-name {
				width: auto;
				float: left;
				font-size: 24px;
				line-height: 66px;
				padding: 0px 20px 0px 20px;
				box-sizing: border-box;
			}
		}
	}

	.app--footer-wrapper {
		width: 100%;
		height: 40px;
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;

		.app--footer-content {
			width: 100%;
			max-width: 900px;
			min-width: 300px;
			margin-left: auto;
			margin-right: auto;	

			.app--copyright {
				width: 100%;
				text-align: center;
				line-height: 40px;
			}
		}
	}

	.app--content-wrapper {
		width: 100%;
		margin-top: 100px;
		margin-bottom: 20px;
		
		.app--content-content {
			width: 100%;
			max-width: 900px;
			min-width: 300px;
			margin-left: auto;
			margin-right: auto;
			box-sizing: border-box;

			.app--primary-menu {
				width: 30%;
				float: left;
				padding: 20px;
				box-sizing: border-box;
				position: sticky;
				top: 100px;

				.app--menu-items {
					width: 100%;
					height: auto;
					float: left;

					.app--menu-item {
						width: 100%;
						line-height: 40px;
						text-transform: uppercase;
						color: #3689e6;
						cursor: pointer;
						font-weight: 400;
						letter-spacing: 2px;

						&:hover {
							color: #002e99;
						}
					}
				}
			}

			.app--primary-content {
				width: 70%;
				float: left;
				padding: 20px;
				box-sizing: border-box;
				margin-bottom: 100px;

				.app--content-items {
					width: 100%;
					height: auto;
					float: left;

					.content--about {
						width: 100%;
						height: auto;
						padding: 20px;
						font-size: 32px;
						color: #ffffff;

						.animated--transition {
							color: #3689e6;
							transition-delay: 1s;
							transition: color 0.5s ease-in-out, font-size 0.5s ease-in-out;

							&:hover {
								color: #0d52bf;
							}
						}
					}

					.content--more {
						width: 100%;
						margin-top: 20px;

						.animated--transition {
							color: #667885;
							transition-delay: 1s;
							transition: color 0.5s ease-in-out, font-size 0.5s ease-in-out;

							&:hover {
								color: #95a3ab;
							}
						}
					}

					.app--content-item {
						width: 100%;
						padding: 20px;
						box-sizing: border-box;
						border-radius: 6px;
						border: 2px dashed #273445;
						margin-bottom: 20px;
						position: relative;

						.content--count {
							width: 40px;
							height: 40px;
							line-height: 40px;
							font-size: 18px;
							text-align: center;
							position: absolute;
							top: -10px;
							right: -10px;
							border-radius: 2.5px;
							color: #ffffff;
							background-color: #0d52bf;
						}

						&:hover {
							border: 2px dashed #3c4857;
							box-shadow: 0px 0px 25px -5px #273445;
						}

						.content--header {
							width: 100%;
							font-size: 18px;
							line-height: 40px;
							margin-bottom: 10px;
						}

						.content-content {
							width: 100%;
							text-wrap: normal;
							margin-bottom: 10px;

							pre {
								width: 100%;
								padding: 10px;
								box-sizing: border-box;
								border-radius: 3px;
							}

							code {
								white-space: pre-wrap;
								word-wrap: break-word;
							}
						}

						.content--image {
							width: 100%;
							min-height: 40px;
							border-radius: 3px;
							margin-bottom: 10px;

							img {
								width: 100%;
								height: auto;
								padding: 0px;
							}

							.image--description {
								margin-top: 20px;
								box-sizing: border-box;
							}
						}

						.content--footer {
							width: 100%;
							height: 40px;

							.app--sc-icons {
								width: 40px;
								height: 40px;
								line-height: 60px;
								text-align: center;

								&:hover {
									background-color: #273445;
									border-radius: 3px;
									cursor: pointer;
								}
							}
						}
					}

					.app--content-item.no--border {
						border: 2px solid transparent;
					}
				}
			}

			@media screen and (max-width:900px) {
				.app--primary-menu {
					width: 100%;
					.app--menu-items {
						width: 100%;
						.app--menu-item {
							background-color: rgba(13, 82, 191, 0.2);
							margin-bottom: 20px;
							text-align: center;
							border-radius: 3px;

							&:hover, &:active {
								background-color: #0d52bf;
								color: #ffffff;
							}
						}
					}
				}

				.app--primary-content {
					width: 100%;
				}
			}
		}
	}
}
